@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
    135deg,
    rgba(108, 182, 217, 1) 0%,
    rgba(243, 187, 216, 1) 43%,
    rgba(254, 240, 196, 1) 100%
  );
  background-size: 1000% 1000%;
  animation: animate 120s ease-in-out infinite;
  position: relative;
}

html,
body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
}

.bs-preview,
.audiophile-preview {
  animation: float1 15s ease infinite;
}

.ct-preview,
.comingsoon-preview {
  animation: float2 15s ease infinite;
}

.portfolio-preview {
  animation: float3 15s ease infinite;
}

.ct-project-description::after {
  content: "Responsive, API-connected website that displays information about countries from around the world. Coded with React and Typescript.";
}

.bs-project-description::after {
  content: "Full-stack project - Online bookstore coded with the MERN stack.";
}

.portfolio-project-description::after {
  content: "Find out more about the website you're browsing right now.";
}

.audiophile-description::after {
  content: "Multi-page, responsive e-commerce website developed with Vue.js and SCSS.";
}

.comingsoon-description::after {
  content: "The journey continues... a few more projects are yet to be added. Until then, why not get in touch?";
}

.project-description::after {
  opacity: 0;
  transition: all 0.5s ease;
}

.preview:hover ~ .project-description::after {
  opacity: 0.8;
}

.portfolio-content {
  background-image: url("./img/coding-pattern-bk.png");
  background-size: contain;
}

@keyframes animate {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

@keyframes float1 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(2rem, 3rem);
  }
  50% {
    transform: translate(-2rem, 1rem);
  }
  75% {
    transform: translate(-3rem, -1rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes float2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-1rem, 3rem);
  }
  50% {
    transform: translate(-2.5rem, 2rem);
  }
  75% {
    transform: translate(3rem, -1rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes float3 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2rem, 2rem);
  }
  50% {
    transform: translate(1.5rem, -1.5rem);
  }
  75% {
    transform: translate(1rem, 3rem);
  }
  100% {
    transform: translate(0, 0);
  }
}
